import Layout from "../components/Layout";

function Insights() {
  return (
    <Layout>
        <h3>Insights Route</h3>
    </Layout>
  );
}

export default Insights;
