import checkemailsrc from "../assets/check-your-email.svg";
import { useLocation, Link } from 'react-router-dom';
import Button from "../components/Button";
import { EmailCodeVerify } from "./EmailCodeVerify";

function VerifyEmail() {
  const location = useLocation();
  const email = location?.state?.email;

  return (
    <div className="email-verify-container">
      <div className="email-verify-inner">
        <img className="email-verify-icon" src={ checkemailsrc } />
        <h1>Check your email</h1>
        <p>We sent a magic sign in link to</p>
        <p className="techy-text">{ email || 'No email provided' }</p>
        <p><Link to={"/verify/emailcode"}>Verify Code Manually</Link></p>
        <p>
          <Link to="/">Back to sign in</Link>
        </p>
      </div>
    </div>
  );
}

export default VerifyEmail;
