import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import useAuth from "../hooks/useAuth";
import { getUserData, updateName } from "../api/account";
import { Formik } from "formik";
import Input from "../components/Input";
import Button from "../components/Button";

function Account() {
  const { setSession,getSession,getUser } = useAuth();
  const [ userSession, setUserSession ] = useState();
  const [userEmail,setUserEmail] = useState();
  useEffect(() => {
    const effect = async () => {
      const userData = await getUserData()
      setUserSession(userData)
    };
    effect();
  }, []);


  function logOut() {
    setSession();
    window.location = '/';
  }
  return (
<Layout>
      {userSession?<>
      <h3>Account</h3>

      <Formik
            initialValues={{name:userSession?.name}}
            onSubmit={async (values, { setSubmitting }) => {
              let emailResponse = await updateName(values.name);
              console.log(emailResponse)
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit}>
                <p>Email:<b>{userSession.email}</b></p>
                <Input
                  type="text"
                  name="name"
                  label="Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.name} />
                <Button className={`gradient ${isSubmitting ? 'loading' : ''}`} type="submit" disabled={isSubmitting}>
                  Update Name
                </Button>
                <div className="form-error">
                  {errors.email && touched.email && errors.email}
                </div>
              </form>
            )}
          </Formik>
          <a className="btn" onClick={ logOut }>Sign out</a>
 </>:
  <p>Loading...</p>
}
</Layout>

  );
}

export default Account;
