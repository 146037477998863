import Select, { components } from "react-select";
import { FaPlus } from "react-icons/fa";
import { Fragment, useState, useRef, useEffect } from "react";

const TaskDropdown = ({ children }) => {
    const [focusControl, setFocusControl] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [showProjectSelect, setShowProjectSelect] = useState(false);

    const handleTaskFocus = () => {
        setFocusControl(true);
        setShowSuggestions(true);
    };

    const handleTaskBlur = () => {
        // setFocusControl(false);
        // setShowSuggestions(false);
    };

    const projectControlClick = () => {
        setFocusControl(true);
        setShowProjectSelect(true);
    };

    const taskDropdownRef = useRef(null);
    const taskControlRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                taskDropdownRef.current &&
                !taskDropdownRef.current.contains(event.target) &&
                taskControlRef.current &&
                !taskControlRef.current.contains(event.target)
            ) {
                setShowSuggestions(false);
                setFocusControl(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [taskDropdownRef, taskControlRef]);

    const projectDropdownRef = useRef(null);
    const projectControlRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                projectDropdownRef.current &&
                !projectDropdownRef.current.contains(event.target) &&
                projectControlRef.current &&
                !projectControlRef.current.contains(event.target)
            ) {
                setShowProjectSelect(false);
                setFocusControl(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [projectDropdownRef, projectControlRef]);

    return (
        <div className="task-dropdown-container">
            <div className={`task-control ${focusControl ? "is-focused" : ""}`}>
                <input
                    type="text"
                    placeholder="What are you doing?"
                    className="task-input"
                    onFocus={handleTaskFocus}
                    onBlur={handleTaskBlur}
                    ref={taskControlRef}
                />
                <div className="project-control" onClick={projectControlClick} ref={projectControlRef}>
                    Choose project
                </div>
            </div>
            {showSuggestions && (
                <div className="dropdown dropdown-left" ref={taskDropdownRef}>
                    <div className="dropdown-title">Suggested tasks</div>
                    <div className="dropdown-item">Example suggestion</div>
                </div>
            )}
            {showProjectSelect && (
                <div className="dropdown dropdown-right" ref={projectDropdownRef}>
                    <div className="dropdown-title">Suggested projects</div>
                    <div className="dropdown-item">Example project</div>
                </div>
            )}
        </div>
    );
};

export default TaskDropdown;
