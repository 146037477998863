import { Formik } from 'formik';
import React from 'react'
import Button from '../components/Button'
import Input from '../components/Input';
import { tokenSignIn } from '../api/user';

export const EmailCodeVerify = (props) => {
  return (
<>
      <Formik
      initialValues={{ token: ''}}

      onSubmit={async (values, { setSubmitting }) => {
        console.log('val',values)
                let tokenResponse= await tokenSignIn(values.token);
                setSubmitting(false);
              }}>
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
<form onSubmit={handleSubmit}>
  Enter Code Manually
                      <Input
                    type="text"
                    name="token"
                    label="Email Token"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.token}
                     />
                  <Button className={`gradient ${isSubmitting ? 'loading' : ''}`} type="submit" disabled={isSubmitting}>
                  Verify Code
                  </Button>
                  <div className="form-error">
                    {errors.email && touched.email && errors.email}
                  </div>
                </form>
              )}
            </Formik>
      </>
    )
}