
import { NavLink as Link } from "react-router-dom";

const NavLink = ({ icon, to, children }) =>{
    return(
        <li>
            <Link className="nav-link" to={ to }>
                <div className="nav-link-inner">
                    <div className="icon">
                        { icon }
                    </div>
                    <div className="label">
                        { children }
                    </div>
                </div>
            </Link>
        </li>
    )
}

export default NavLink;