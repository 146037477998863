import axios from '../lib/axios';

const apiBaseUrl = 'authentication';

const getUserFromToken = async (token) => {
	try {
		var response = await axios.post(apiBaseUrl + '/verify', { token: token });
		return(response.data);
	} catch (err) {
		console.error(err);
		return(err);
	}
};

export {
	getUserFromToken
}