import TaskDropdown from "./TaskDropdown";
import TrackerTimer from "../components/TrackerTimer";

const TrackerBar =({ children }) =>{
    return(
        <div className="tracker-bar">
            <TaskDropdown />
            <TrackerTimer />            
        </div>
    )
}

export default TrackerBar;