import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.API_BASE_URL || 'https://api.wrkti.me/'
});

const isCachableRequest = config => {
  const cachableRoutes = [
    'authentication/verify'
  ];

  return cachableRoutes.includes(config.url);
};

const cachedAuthenticationResponse = (error) => {
  let session = localStorage.getItem('wrktimesession');
  try {
    session = JSON.parse(session);
  } catch (error) { };

  if (session?.token && session?.user?.id && session?.user?.name) {
    return {
      data: {
        requestCached: true,
        message: "App is offline, returning cached data.",
        id: session.user?.id || null,
        name: session.user?.name || null
      },
      status: 200,
      statusText: "OK",
      headers: {},
      config: error.config
    };
  }
}

const createResolvedResponse = (error) => {
  if (isCachableRequest(error.config)) {
    if (error.config.url === 'authentication/verify') {
      return cachedAuthenticationResponse(error);
    }
  }
  return false; // No resolved response, reject error
};

// Add a request interceptor
axiosInstance.interceptors.request.use(config => {
  // Do something before request is sent
  return config;
}, error => {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(response => {
  // Do something with response data
  return response;
}, error => {
  // Change the error into a resolved response if error.code === 'ERR_NETWORK'
  if (error.code === 'ERR_NETWORK') {
    const resolvedResponse = createResolvedResponse(error);
    if (resolvedResponse) {
      return Promise.resolve(resolvedResponse);
    }
  }

  // Return the error as a rejected promise if error.code !== 'ERR_NETWORK'
  return Promise.reject(error);
});

export default axiosInstance;
