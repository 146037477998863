import React, { useState, useEffect } from "react";
import logo from "../logo.svg";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Layout from "../components/Layout";
import TrackerBar from "../components/TrackerBar";
import TrackerEntries from "../components/TrackerEntries";

function Tracker() {
  return (
    <Layout>
        <Link to="/" className="tracker-page-logo text-center d-block d-md-none mb-5">
          <img src={logo} alt="Wrkti.me" style={{width: 160}} />
        </Link>
        <TrackerBar />
        <TrackerEntries />
    </Layout>
  );
}

export default Tracker;
