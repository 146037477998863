import { Link } from "react-router-dom";
import logo from "../logo.svg";
const NavBar =({ children }) =>{
    return(
        <nav>
            <Link to="/" className="logo">
                <img src={logo} className="login-logo" alt="Wrkti.me" />
            </Link>
            <ol>
                { children }
            </ol>
        </nav>
    )
}

export default NavBar;