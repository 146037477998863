import axios from '../lib/axios';

const apiAuthBaseUrl = 'authentication';

const emailSignIn = async (email) => {
	try {
		var response = await axios.post(apiAuthBaseUrl + '/email', { email: email });
		return(response.data);
	} catch (err) {
		console.error(err);
		return(err);
	}
};
const tokenSignIn = async (token) => {
	try {
		var response = await axios.get(apiAuthBaseUrl + '/email/return', { token: token });
		console.log('response',response)
		return(response.data);
	} catch (err) {
		console.error(err);
		return(err);
	}
};

export {
	emailSignIn,
	tokenSignIn
}