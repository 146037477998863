import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import { FaPlay, FaStop, FaPlus } from "react-icons/fa";
import { Fragment, useState, useEffect, useRef } from "react";

const TrackerTimer = ({ children }) => {
    const [inputValue, setInputValue] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);
    const [timerRunning, setTimerRunning] = useState(false);

    const dropdownRef = useRef(null);

    function formatTime(totalSeconds) {
        const hours = Math.floor(totalSeconds / 3600)
            .toString()
            .padStart(2, "0");
        const minutes = Math.floor((totalSeconds % 3600) / 60)
            .toString()
            .padStart(2, "0");
        const seconds = (totalSeconds % 60).toString().padStart(2, "0");
        return `${hours} : ${minutes} : ${seconds}`;
    }

    function handleInputChange(event) {
        const value = event.target.value.replace(/[^\d]/g, "").padStart(6, "0");
        const parts = value.match(/(\d{2})(\d{2})(\d{2})$/);
        const formattedValue = parts
            ? `${parts[1]} : ${parts[2]} : ${parts[3]}`
            : "00 : 00 : 00";
        setInputValue(formattedValue);
    }

    function handleInputClick() {
        setShowDropdown(true);
    }

    function handleInputBlur() {
        setShowDropdown(false);
    }

    useEffect(() => {
        const handleDocumentClick = (e) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(e.target)
            ) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("click", handleDocumentClick);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    const startTimer = () => {
        setTimerRunning(true);
    };

    const stopTimer = () => {
        setTimerRunning(false);
    };

    // const [time, setTime] = useState(0);
    // const [intervalId, setIntervalId] = useState(null);

    // const startTimer = () => {
    //   const id = setInterval(() => {
    //     setTime((time) => time + 1);
    //   }, 1000);
    //   setIntervalId(id);
    // };

    // const stopTimer = () => {
    //   clearInterval(intervalId);
    //   setIntervalId(null);
    // };

    // useEffect(() => {
    //   setInputValue(formatTime(time));
    // }, [time]);

    return (
        <Fragment>
            <div className="tracker-time-input-container" ref={dropdownRef}>
                <input
                    type="text"
                    className="tracker-time-input"
                    placeholder="00 : 00 : 00"
                    value={inputValue}
                    onChange={handleInputChange}
                    onClick={handleInputClick}
                    // onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                />
                {showDropdown && (
                    <div className="dropdown-container">
                        <div className="dropdown-item">Option 1</div>
                        <div className="dropdown-item">Option 2</div>
                        <div className="dropdown-item">Option 3</div>
                    </div>
                )}
            </div>
            <div className="tracker-button-container">
                {timerRunning ? (
                    <button
                        className="tracker-button is-active"
                        onClick={stopTimer}
                    >
                        <FaStop />
                    </button>
                ) : (
                    <button className="tracker-button" onClick={startTimer}>
                        <FaPlay />
                    </button>
                )}
            </div>
        </Fragment>
    );
};

export default TrackerTimer;
