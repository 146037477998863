import axios from '../lib/axios';

const apiAuthBaseUrl = 'account';

const getUserData = async () => {
	try {
		let response = await axios.get(apiAuthBaseUrl);
		return(response.data);
	} catch (err) {
		console.error(err);
		return(err);
	}
};

const updateName = async (name)=>{
	try{
		let response = await axios.post(apiAuthBaseUrl+'/update',{name:name})
		console.log('res',response)
		return response.data
	}catch(err){
		console.log(err)
		return err
	}
}

export {
    getUserData,
    updateName
}