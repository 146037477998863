import React from 'react'

export default function Button({ href, target='_self', type='link', className = '', children, onClick, disabled }) {
  return (
    <>
      { type === 'link' &&
        <a href={ href } target={ target } className={`btn ${className}`} onClick={ onClick }>
            { children }
        </a>
      }
      { type !== 'link' &&
        <button type={ type } disabled={ disabled } className={`btn ${className}`}>
          { children }
        </button>
      }
    </>
  )
}