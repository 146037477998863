import React from "react";
import logo from "../logo.svg";
import signupBanner from "../assets/wrktime-signup-banner.svg";

import { emailSignIn } from "../api/user";
import { Formik } from 'formik';

import Button from "../components/Button";
import Input from "../components/Input";

import { Link, useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();

  function handleEmailRedirect(emailAddress) {
    navigate('/verify/email', { state: { email: emailAddress } });
  }

  return (
    <div className="login-wrapper">
      <header className="login-header">
        <Link to="/">
          <img src={logo} className="login-logo" alt="Wrkti.me" />
        </Link>
      </header>
      <div className="container-wrapper">
        <div className="container -login">
          <div className="container-inner">
            <h1 className="title mt-0 mb-3">Let's get started</h1>
            <Button
              href="https://api.wrkti.me/authentication/apple"
              className="apple-sign-in outline"
            >
              Sign in with Apple
            </Button>
            <Button
              href="https://api.wrkti.me/authentication/google"
              className="google-sign-in outline"
            >
              Sign in with Google
            </Button>
            <div className="or-separator">Or</div>
            <Formik
              initialValues={{ email: ''}}
              validate={values => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Email is required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                let emailResponse = await emailSignIn(values.email);
                if (emailResponse.success) {
                  handleEmailRedirect(values.email);
                }
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                <form onSubmit={handleSubmit}>
                  <Input
                    type="email"
                    name="email"
                    label="Email"
                    placeholder="elon@tesla.com"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email} />
                  <Button className={`gradient ${isSubmitting ? 'loading' : ''}`} type="submit" disabled={isSubmitting}>
                    Continue with Email
                  </Button>
                  <div className="form-error">
                    {errors.email && touched.email && errors.email}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <div className="container -signup">
          <div className="container-inner">
            <h1 className="title secondary-color light-weight m-0">
              <div>
                Focus on your <strong>wrk</strong>
              </div>
              <div>
                let us focus on your <strong>ti.me</strong>
              </div>
            </h1>
            <img src={signupBanner} className="banner" alt="Sign Up Banner" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
