import NavBar from "../components/NavBar";
import NavLink from "../components/NavLink";
import { MdAccessTime, MdOutlineInsights, MdOutlineAccountCircle } from 'react-icons/md';

const Layout =({children}) =>{
    return(
        <div className="layout-wrapper">
            <NavBar>
                <NavLink to={'/'} icon={ <MdAccessTime /> }>
                    Tracker
                </NavLink>
                <NavLink to={'/insights'} icon={ <MdOutlineInsights /> }>
                    Insights
                </NavLink>
                <NavLink to={'/account'} icon={ <MdOutlineAccountCircle /> }>
                    Account
                </NavLink>
            </NavBar>
            <main>{children}</main>
        </div>
    )
}

export default Layout;