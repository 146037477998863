import axios from '../lib/axios';

const apiAuthBaseUrl = 'tracker';

const sync = async (entries) => {
	try {
		var response = await axios.post(apiAuthBaseUrl + '/sync', { entries: entries });
		return(response.data);
	} catch (err) {
		console.error(err);
		return(err);
	}
};

export {
	sync
}