import React, { Component, createContext, useEffect, useState } from "react";
import axios from '../lib/axios';

import { getUserFromToken } from '../api/authentication';

const initialSession = {
  token: null,
  user: {
    id: null,
    name: null
  }
};

const getSession = function () {
  let session = localStorage.getItem('wrktimesession');
  try {
    session = JSON.parse(session);
  } catch (error) {
    session = initialSession;
  }
  return session;
};

const setSession = (session) => {
  if (session?.token) {
    const sessionJSON = JSON.stringify({
      token: session.token,
      user: {
        id: session.user?.id || null,
        name: session.user?.name || null
      }
    });
    localStorage.setItem('wrktimesession', sessionJSON);
    axios.defaults.headers.common.Authorization = `Bearer ${session.token}`;
  } else {
    localStorage.removeItem('wrktimesession');
    delete axios.defaults.headers.common.Authorization;
  }
};

const initialState = {
  ...initialSession,
  authenticated: false,
  initialized: false,
  setSession: setSession,
  getSession: getSession,
  initialize: () => {},
  getUser: async function () {
    const session = getSession();
    if (session?.token) {
      const user = await getUserFromToken(session.token);
      if (user?.id) {
        const newSession = {
          token: session.token, // this should come from the server
          user: {
            id: user.id, // this should be the uuid of the user table
            name: user.name, // should be the name from the user table
            email:user.email
          }
        };
        setSession(newSession);
        return {
          ...newSession,
          authenticated: true
        }
      } else {
        setSession(initialSession);
        return {
          ...initialSession,
          authenticated: false
        };
      }
    }
  }
};

export const AuthContext = createContext(initialState);

export default function AuthContextProvider({ children }) {
  const [context, setContext] = useState(initialState);
  const updateContext = (contextUpdates = {}) => setContext(currentContext => ({ ...currentContext, ...contextUpdates }));

  useEffect(() => {
    const initialize = async () => {
      if (!context.initialized) {
        const userSession = await context.getUser();
        updateContext({
          ...userSession,
          initialized: true
        });
        return userSession;
      }
    }
    updateContext({
      initialize: initialize
    });
    initialize();
  }, []);

  return (
    <AuthContext.Provider value={context}>
      { context.initialized && children }
    </AuthContext.Provider>
  )
}
