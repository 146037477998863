import "./scss/global.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthGuard from "./components/AuthGuard";

import Tracker from "./pages/Tracker";
import Insights from "./pages/Insights";
import Account from "./pages/Account";
import Callback from "./pages/Callback";
import VerifyEmail from "./pages/EmailVerify"

import AuthContextProvider from './contexts/AuthContext';
import { EmailCodeVerify } from "./pages/EmailCodeVerify";

function App() {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={
              <AuthGuard>
                <Tracker />
              </AuthGuard>
            } />
            <Route path="insights" element={
              <AuthGuard>
                <Insights />
              </AuthGuard>
            } />
            <Route path="account" element={
              <AuthGuard>
                <Account />
              </AuthGuard>
            } />
            <Route path="auth/callback">
              <Route path=":code"  element={<Callback />} />
            </Route>
            <Route path="verify/email" element={<VerifyEmail />} />
          </Route>
            <Route path="verify/emailcode" element={<EmailCodeVerify />} />
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  );
}

export default App;
