import { useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

function Callback() {
  const { code } = useParams();
  const { setSession, initialize } = useAuth();

  useEffect(() => {
    setSession({ token: code });
    initialize();
  }, [code]);

  return (
    <Navigate to='/' />
  );
}

export default Callback;
