import { useEffect, useState } from "react";

import { sync } from "../api/tracker";

import {
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    Type as ListType,
  } from 'react-swipeable-list';

import 'react-swipeable-list/dist/styles.css';

const TrackerEntries =({ children }) =>{

    const [entries, setEntries] = useState();

    useEffect(() => {
        const effect = async () => {
          const syncResponse = await sync()
          setEntries(syncResponse.entries);
        };
        effect();
      }, []);

    const handleDelete = id => () => {
        setEntries(entries.filter(entries => entries.id !== id));
    };

    const formatDuration = minutes => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:00`;
      };

    const trailingActions = ({ id }) => (
        <TrailingActions>
            <SwipeAction destructive={true} onClick={handleDelete(id)}>
                <div className="swipe-action-item">Delete</div>
            </SwipeAction>
        </TrailingActions>
    );

    return (
        <div className="tracker-entries">
            { entries && entries.length > 0 ? (
                <SwipeableList
                    fullSwipe={true}
                    destructiveCallbackDelay={500}
                    type={ListType.IOS}
                >
                    { entries.map(({ id, name, color, description, day, short_month, duration }) => (
                        <SwipeableListItem
                            key={id}
                            trailingActions={trailingActions({ id })}
                        >
                            <div className={`tracker-entry-color ${'bg-' + color}`}></div>
                            <div className="tracker-entry-content">
                                <div className="tracker-entry-date">
                                    <div className="tracker-entry-date-month">{ short_month }</div>
                                    <div className="tracker-entry-date-day">{ day }</div>
                                </div>
                                <div className="tracker-entry-project">
                                    <div className="tracker-entry-description">
                                        { description }
                                    </div>
                                    <div className="tracker-entry-project-name">
                                        { name }
                                    </div>
                                </div>
                                <div className="tracker-entry-duration">
                                    { formatDuration(duration) }
                                </div>
                            </div>
                        </SwipeableListItem>
                    ))}
                </SwipeableList>
            ) : ( 
                <div className="tracker-entries-empty">Loading entries...</div> 
            )}
        </div>
    )
}

export default TrackerEntries;